const log = require('loglevel');
const { logToSentry, logSentryError } = require('.');

module.exports.initialize = () => {
  const originalFactory = log.methodFactory;
  log.methodFactory = (methodName, logLevel, loggerName) => {
    const rawMethod = originalFactory(methodName, logLevel, loggerName);

    return (...args) => {
      if (methodName === 'error') {
        const message = args.filter((arg) => typeof arg === 'string').join(' ');
        const errors = args.filter((arg) => arg instanceof Error);

        if (errors.length) {
          errors.forEach((error) => logSentryError(error, message));
        } else if (message) {
          logToSentry(message);
        }
      }
      rawMethod(...args);
    };
  };

  if (process.env.NODE_ENV === 'production') {
    log.setLevel(log.levels.INFO);
  } else {
    log.setLevel(log.levels.DEBUG);
  }
};
